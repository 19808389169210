import React, { useState } from 'react';
import { fixTooltip } from '../../constant/commonUtils';
import { IoMdInformationCircleOutline } from "react-icons/io";
const TooltipButton = ({ icon, tooltipText }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  // const sanitizedText = tooltipText.replace(/<\/?[^>]+(>|$)/g, "");
  const sanitizedText = tooltipText
  .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
  .replace(/&[^;]+;/g, " "); // Remove all HTML entities like &nbsp;, &amp;, etc.


  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Button with Icon */}
      <button
        className="btn p-0"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        style={{ background: 'none', border: 'none', margin: '0px', }}
      >
        <IoMdInformationCircleOutline style={{ width: '25px', height: 'auto', color: 'rgba(1, 146, 166, 1)', }} />
      </button>

      {/* Tooltip */}
      {showTooltip && (
        <div
          style={{
            position: 'absolute',
            bottom: '95%',
            left: '180px',
            transform: 'translateX(-50%)',
            marginBottom: '10px',
            backgroundColor: '#fff',
            color: '#000',
            fontSize: '14px',
            padding: '10px',
            borderRadius: '4px',
            zIndex: 1000,
            whiteSpace: 'normal',
            border: '1px solid white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '450px',
            maxWidth: '500px',
            overflow: 'visible',
            textAlign: 'left',
          }}
        >
          {fixTooltip(sanitizedText)}

          {/* Tooltip Arrow */}
          <div
            style={{
              position: 'absolute',
              bottom: '-10px',
              left: '15%',
              transform: 'translateX(-50%)',
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid #fff',
              zIndex: 1000,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TooltipButton;
